/* abstracts */
@import "abstracts/interpolate";
@import "abstracts/include-media";
@import "abstracts/aspect-ratio";
@import "abstracts/variables";

/* base */
@import "base/normalize";
@import "base/default";

$grid-columns: 12;
$grid-gutter-width: 16px;
$grid-breakpoints: (
  xs: 0,
  s: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1440px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1280px,
);
@import "../../node_modules/bootstrap/scss/bootstrap-grid.scss";
// @import "../../node_modules/sortable-tablesort/sortable.min.css";

/* utils */

@import "helpers/utils";
@import "helpers/sticky-footer";

/* vendors */
// @import "../../node_modules/swiper/swiper-vars.scss";
// @import "../../node_modules/swiper/swiper.scss";
// @import "../../node_modules/swiper/modules/navigation/navigation.scss";

/* components */
@import "components/wrapper";
@import "components/text";
@import "components/btn";
@import "components/block-switch";
@import "components/cursor";
@import "components/block-selection";
@import "components/screen";

@import "layout/site-content";
@import "layout/site-header";
@import "layout/site-footer";

@import "pages/page-home";
@import "pages/page-index";
@import "pages/page-projet";
@import "pages/page-default";
@import "pages/page-404";
@import "pages/page-maintenance";
