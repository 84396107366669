.error404 {
  .col-404-content {
    padding-top: 2.0625rem;
    padding-top: clamp(
      2.0625rem,
      0.3938106796116505rem + 7.119741100323624vw,
      8.9375rem
    );
    padding-bottom: 2.0625rem;
    padding-bottom: clamp(
      2.0625rem,
      0.3938106796116505rem + 7.119741100323624vw,
      8.9375rem
    );
  }
}
