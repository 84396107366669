.screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.video-wrapper,
.video-container {
  width: 100%!important;
  padding-top: 56.25%;
  position: relative;
  
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.go-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.overflow-hidden {
  overflow: hidden!important;
}