.page-home,
.page-architecture,
.page-creative {
  .site-content {
    @include media(">=768px") {
      padding: 5vh 0;
    }
  }
}

.page-projet {
  .site-content {
    // margin-top: 50px;
    height: calc(100vh - 60px);

    @include media(">=768px") {
      padding: 5vh 0;
    }
  }
}

.site {
  opacity: 0 !important;
  transition: opacity 250ms;
  &.is-fadeout {
    opacity: 0 !important;
  }
  &.is-fadein {
    opacity: 1 !important;
  }

  .infos.is-alt .lines,
  .contact.is-alt .lines,
  .index.is-alt .lines {
    transition: transform 500ms;
    transform: translateY(100%);
  }

  &.is-fadein .infos.is-alt .lines,
  &.is-fadein .contact.is-alt .lines,
  &.is-fadein .index.is-alt .lines {
    transform: translateY(0);
  }

  .selection .featured figure {
    transition: all 250ms;
    transform: scale(0.8);
    opacity: 0;
  }

  &.is-fadein .selection .featured figure {
    transform: scale(1);
    opacity: 1;
  }
}
