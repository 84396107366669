.page-index {
  .site {
    height: calc(100% - 60px);
  }
  main {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(">=768px") {
      height: 100%;
    }
  }
  .table {
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    margin: 0 auto;
    max-width: 1100px;
    height: auto;
    overflow: scroll;
    max-height: calc(100% - 10vh);
    cursor: pointer;
    /* set a max-height to trigger invisible overflow */

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .line {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      border-bottom: 1px solid black;
      padding: 1em 0;
      @include media("<768px") {
        color: black;
      }
      color: #b8b8b8;
      transition: color 250ms;
      margin-bottom: 0;

      a {
        color: #b8b8b8;
        @include media("<768px") {
          color: black;
        }
      }

      &.is-thead,
      &:hover,
      &:hover a {
        color: black;
      }

      &.is-thead {
      }
    }
    thead {
      position: sticky;
      top: 0;
      background-color: white;
      font-weight: bold;
      @include media("<768px") {
        display: none;
      }
    }
    .cell {
      @include media("<768px") {
        width: 100%;
      }
      @include media(">=768px") {
        flex: 1;
      }

      padding-right: 1em;
    }

    th.cell {
      &::after {
        margin-left: 3px;
        display: inline-flex;
        content: "▸";
        transform: rotate(0);
      }
      &.dir-u::after {
        transform: rotate(90deg);
      }
      &.dir-d::after {
        transform: rotate(-90deg);
      }
    }

    .projet {
      &:not(th) {
        @include media("<768px") {
          padding-bottom: 0.5em;
        }
      }

      @include media(">=768px") {
        flex: 2;
      }
    }
  }
}
