.page-projet {
  .site-content {
    display: flex;
    align-items: center;
  }
  .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 100%;
    flex-flow: row wrap;
    @include media("<768px") {
      height: calc(100vh - 50px);
      flex-direction: column;
    }
  }
  .projet-infos {
    order: 2;
    @include media(">=768px") {
      position: absolute;
      order: 1;
      padding-top: 0;
      // height: 50%;

      // .infos-title {
      //   position: sticky;
      //   top: 0;
      //   background-color: var(--color-white);
      // }
    }
    @include media("<768px") {
      font-size: 15px;
      width: 100%;
    }

    display: flex;
    flex-flow: column nowrap;
    width: 20em;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    justify-content: flex-start;
    z-index: 2;
    li {
      width: 100%;
      margin-bottom: 0.5em;
      padding-bottom: 0.5em;
    }

    .read-more {
      img {
        width: 24px;
        height: auto;
        transform: rotate(0);
        transition: transform 500ms;
        &:hover {
          transform: rotate(270deg);
        }
      }
    }
  }
  .swiper {
    width: 100%;
    height: 100%;

    @include media("<768px") {
      // font-size: 15px;
      flex: 1;
    }

    .swiper-slide {
      width: auto !important;
      opacity: 1;
      transition: opacity 500ms;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: url("assets/img/arrow.svg"), auto;
      cursor: url("assets/img/uti-arrow-next.svg"), auto;

      @include media("<768px") {
        width: 100% !important;
      }
    }

    .swiper-slide-prev {
      cursor: url("assets/img/uti-arrow-prev.svg"), auto;
    }

    .is-changing {
      cursor: none;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    video {
      width: 100%;
      object-fit: contain;
      @include media(">=768px") {
        height: 100%;
        max-width: calc(100vw - 60px);
      }
    }

    // .swiper-button-prev,
    // .swiper-button-next {
    //   --swiper-navigation-size: 88px;
    //   top: auto;
    //   bottom: 0;
    //   color: black;
    //   margin: 0;
    //   padding: 0;
    // }
    // .swiper-button-prev {
    //   left: 30px;
    // }
    // .swiper-button-next {
    //   right: 30px;
    // }
  }
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 800px;
}

/* The Close Button */
.close-modal {
  color: #aaa;
  float: right;
  width: 24px;
  height: auto;
  transform: rotate(45deg);
  transition: transform 500ms;
  &:hover {
    transform: rotate(315deg);
  }
}

.close-modal:hover,
.close-modal:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
