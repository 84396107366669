.page-home,
.page-projets {
  .site-content {
    .wrapper {
      @include media("<1024px") {
        max-width: 100%;
      }
    }
  }
}

.intro-sizer {
  width: 100%;
  // height: 500vh;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: -1;
  // pointer-events: none;
  position: relative;

  > div {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.trigger-shapes {
      margin-top: 100vh;
      // background-color: lightblue;
    }
    &.trigger-logo {
      // background-color: lightpink;
    }
    &.trigger-out {
      margin-bottom: 50vh;
      // background-color: bisque;
    }
  }
}

.page-home .site-header {
  z-index: 3;
}

.page-home .intro {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  background-color: transparent;
  z-index: 4;
  //

  #svg-container {
    // display: inline-block;
    // position: relative;
    // width: 320px;
    // left: 50%;
    // transform: translate(-50%, 0);
    // padding-bottom: 100%;
    // vertical-align: middle;
    // overflow: hidden;

    #fixed-logo {
      width: 320px;
      height: auto;
      position: absolute;
      // display: block;
      top: 50%;
      left: 50%;
      // width: 422.8px;
      // height: 306.33px;
      transform: translate(-50%, -50%);

      // #T-bar-f,
      // #U-bar-f,
      // #DOT-f {
      //   visibility: hidden;
      // }
    }
  }

  svg:nth-child(2) {
    //position: absolute;
    // display: block;
    // top: 50%;
    // left: 50%;
    // width: 422.8px;
    // height: 306.33px;
    // transform: translate(-50%, -50%);

    #T-bar-f,
    #U-bar-f,
    #DOT-f {
      visibility: hidden;
    }
  }
}
