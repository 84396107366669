.selection {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  overflow: hidden;

  @include media(">=768px") {
    padding: 5vh 0;
  }

  .featured-wrapper {
    width: 100%;
    @include media(">=1024px") {
      width: 50%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .featured {
    z-index: 1;
    cursor: none;

    a {
      cursor: none;
    }

    figure {
      @include media(">=1024px") {
        height: 70vh;
        max-width: 100%;
      }
      position: relative;
    }
    figcaption {
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      padding: 1em;

      opacity: 0;
    }
    .image {
      position: relative;
      width: 100%;
      // height: 100%;
      transition: transform 1000ms, background-color 250ms;
      // transform: scale(1);
    }
    img {
      // height: 100%;

      width: auto;
      object-fit: contain;
      @include media(">=1024px") {
        max-height: 65vh;
      }
    }
    &:hover {
      z-index: 2;

      figure {
        .image {
          transform: scale(1.1);
          // background-color: rgba(255, 255, 255, 1);
          // backface-visibility: hidden;
        }
        img {
          opacity: 0.2;
        }
        figcaption {
          opacity: 1;
        }
      }
    }
  }
}
