#cursor {
  font-size: 4em;

  display: none;
  @media (pointer: fine) {
    display: block;
    position: absolute;
    // width: 40px;
    // height: 38px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    backface-visibility: hidden;
    user-select: none;
    pointer-events: none;
    z-index: 3;
    transform: translate(-50px, -50px), rotate(0);
    transform-origin: 25px 25px;

    opacity: 0;

    div {
      position: relative;
      top: -12px;
      left: 4px;
      line-height: 1;
    }

    &.is-shown {
      // transition: transform 2.5s;
      opacity: 1;
      transform: rotate(180deg);
      transition: transform 500ms, opacity 250ms;
    }

    &.is-hidden {
      opacity: 0;
      transform: rotate(0);
      // transition: transform 500ms, opacity 250ms;
      transition: opacity 150ms;
    }
  }
}

// @keyframes rotating {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
