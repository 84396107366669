.block-switch {
  position: relative;
  display: flex;
  justify-content: center;
  text-transform: uppercase;

  .switch-text {
    min-width: 2em;
    display: none;
    @include media(">=1024px") {
      display: block;
    }
  }

  &.is-active {
    .switch-circle {
      background-color: black;
    }
  }

  &.is-architecture {
    .switch-link-left {
      color: black;
    }
    .switch-circle {
      transform: translateX(-0.5em);
    }
  }

  &.is-creative {
    .switch-link-right {
      color: black;
    }
    .switch-circle {
      transform: translateX(0.5em);
    }
  }

  .switch-circle {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    transition: transform 150ms ease-in;
    left: calc(50% - 8px);
    @include media(">=1024px") {
      left: calc(50% + 20px);
    }
    background-color: black;
    pointer-events: none;
  }

  .switch-link {
    display: flex;
    line-height: 1;
    color: #b8b8b8;

    &:hover {
      color: rgba(0, 0, 0, 0.75);
    }
  }

  .switch-background {
    position: relative;
    // top: -1px;
    display: block;
    width: 1em;
    height: 1em;
    background-color: #b8b8b8;
  }

  .switch-link-left {
    .switch-background {
      margin-left: 0.5em;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:hover {
      ~ .switch-circle {
        transform: translateX(-0.5em);
      }
    }
  }

  .switch-link-right {
    .switch-background {
      margin-right: 0.5em;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:hover {
      ~ .switch-circle {
        transform: translateX(0.5em);
      }
    }
  }
}
