.screen {
  &.contact {
    @include media("height>=500px") {
      height: 100vh;
    }
  }

  &.index {
    display: block;

    .lines {
      padding: 0;
    }
  }

  &.infos {
    @include media(">=1024px", "height>900px") {
      height: 100vh;
    }
  }

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p:last-child {
    margin-bottom: 0;
  }

  .lines {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding: 1em 0;
  }

  .line {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0;
    }

    .cell {
      text-align: left;
    }

    .desc {
      color: #b8b8b8;
      margin-bottom: 1em;
      width: 100%;
    }

    @include media(">=600px") {
      .desc {
        flex: 2;
      }
      .content {
        flex: 5;
      }
    }
  }
}
