.buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.button {
  &:hover {
  }
}
