// .page-home .site-header + .site {
//   margin-top: calc(60px + 500vh);
// }

.site-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 1em;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding-top: 10px;

  // background: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 1) 60%,
  //   rgba(255, 255, 255, 0) 100%
  // );

  background-color: white;

  & + .site {
    margin-top: 60px;
  }

  .logo {
    display: inline-flex;
    @include media(">=768px") {
      padding-left: 1em;
    }

    svg {
      pointer-events: none;
      display: block;
      width: auto;
      height: 30px;
      width: auto;
      @include media(">=768px") {
        transform: translateY(40%);

        height: 40px;
      }

      transition: transform 150ms;
    }

    a:hover {
      svg {
        transform: translateY(0);
      }

      #u,
      #T,
      #I {
        opacity: 1;
      }
    }

    #u,
    #T,
    #I {
      @include media("<768px") {
        opacity: 1;
      }
      opacity: 0;
      transition: opacity 150ms;
    }
  }

  .logo,
  .projets,
  .menu,
  .hamburger {
    flex: 1;
  }

  .menu {
    display: flex;
    justify-content: flex-end;

    a {
      line-height: 1;
      color: #b8b8b8;
      padding: 0 0.5em;

      @include media(">=1280px") {
        padding: 0 1em;
      }

      &:hover {
        color: rgba(0, 0, 0, 0.75);
      }

      &.is-active {
        color: black;
      }
    }
  }

  .projets,
  .menu,
  .hamburger {
    @include media(">=768px") {
      transform: translateY(-50%);
    }
  }

  .menu {
    @include media("<768px") {
      display: none;

      &.open {
        background-color: white;
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        left: 0;
        top: 60px;

        padding: 0.25em 1em 0.75em;
        a {
          padding: 0;
          width: calc(100% / 3);
        }
        a:last-of-type {
          text-align: right;
        }
        a:nth-child(2) {
          text-align: center;
        }
      }
    }
  }

  .hamburger {
    display: flex;
    @include media(">=768px") {
      display: none;

      padding-right: 1em;
    }
    svg {
      height: 20px;
      width: auto;
      margin-left: auto;
    }

    &.open {
      svg {
        rect:first-of-type {
          transform: rotate(-45deg) translate(-5px, -5px);
          transform-origin: 30%;
        }

        rect:last-of-type {
          transform: rotate(45deg) translate(5px, 26px);
          transform-origin: 30%;
        }
      }
    }
  }
}

.page-index .site-header .menu a.is-index,
.page-infos .site-header .menu a.is-infos,
.page-contact .site-header .menu a.is-contact {
  color: #000;
}
