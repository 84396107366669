.page-maintenance {
  .site {
    width: 100%;
    height: 100vh;
    height: 100dvh;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    svg {
      margin-top: auto;
      width: 320px;
      height: auto;
    }

    .contact {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin-top: auto;
      margin-bottom: 1.5em;

      a {
        padding: 0 1.5em;
      }
    }

    .contact {
    }
  }
}
